<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('ticket_tracker')"
                        :isColumns="true"
                        :isFilter="false"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('ticket_tracker')"
                              :isColumns="true"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              :exportExcel="checkPermission('ticketeventapplication_downloadticket')" @exportExcel="downloadExcel">
                <b-row>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('name')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.name"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('surname')">
                            <b-form-input
                                v-model="datatable.queryParams.filter.surname"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox v-model="datatable.queryParams.filter.gender"></gender-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('email')">
                            <b-form-input v-model="datatable.queryParams.filter.email"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox code="payment_status" v-model="datatable.queryParams.filter.status">
                            </parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('ticket_type')">
                            <multi-selectbox
                                :options="ticketTypes"
                                v-model="datatable.queryParams.filter.ticket_event_type_id">
                            </multi-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group :label="$t('country')">
                            <country-selectbox v-model="datatable.queryParams.filter.country_id"></country-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
//components
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

import qs from "qs";
//services
import TicketTrackerService from "@/services/TicketTrackerService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox
    },
    metaInfo() {
        return {
            title: this.$t('ticket_tracker')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('detail'),
                                class: 'ri-search-eye-line',
                                permission: "ticketeventapplication_ticketinformation",
                                callback: (row) => {
                                    this.$router.push('/ticket/tracker/detail/' + row.id)
                                },
                            },
                            {
                                text: this.$t('approval_status'),
                                class: 'ri-check-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "ticketeventapplication_getstatus",
                                show: (row) => {
                                    if (row.ticket_event_type_id == 3) {
                                        return true;
                                    }
                                    return false;
                                },
                                callback: (row) => {
                                    this.updateApproved(row.id)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase('name'),
                        field: 'name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('surname'),
                        field: 'surname',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('email'),
                        field: 'email',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('company_name'),
                        field: 'company',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('gender'),
                        field: 'gender',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('country'),
                        field: this.$i18n.locale=='tr' ? 'country_name':'country_name_en',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('date_of_birth'),
                        field: 'birthdate',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('mobile_number'),
                        field: 'phone',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('ticket_type'),
                        field: this.$i18n.locale=='tr' ? 'ticket_event_type_name':'ticket_event_type_name_en',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('amount'),
                        field: 'price',
                        hidden: false,
                        formatter: this.formatToCurrency
                    },
                    {
                        label: this.toUpperCase('status'),
                        field: 'status',
                        hidden: false,
                        formatFn:(row)=>{
                            if(row == 'waiting_payment'){
                                return this.$t('waiting_payment')
                            } else if(row == 'waiting') {
                                return this.$t('waiting')
                            } else if(row == 'approved') {
                                return this.$t('approved')
                            } else {
                                return row.status
                            }
                        }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            ticketTypes: [
                {value: '1', text: this.$t('individual_ticket')},
                {value: '2', text: this.$t('group_ticket')},
                {value: '3', text: this.$t('student_ticket')},
                {value: '4', text: this.$t('institutional_ticket')},
            ]
        }
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {}
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            TicketTrackerService.downloadExcel(config)
                .then(res => this._downloadFile(res, this.$t('ticket_tracker') + '.xlsx'))
                .catch(err => this.showErrors(err))
        },

        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return TicketTrackerService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;

                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formatToCurrency(value, key, item) {
            return this.$options.filters.formatToCurrency(value, item.currency);
        },
        updateApproved(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_ticket_approved'),
                    confirmButtonText: this.$t('confirm'),
                    showCloseButton: true,
                    closeButtonHtml: '&times;',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        TicketTrackerService.approvedTicket(id)
                            .then((response) => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                            })
                    }
                })
                .catch((error) => {
                    this.$toast.error(this.$t('api.' + (error.response?.data?.message)));
                });
        },
    }
}
</script>
